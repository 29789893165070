<template>
  <div id="nav">
    <nav class="uk-navbar-container uk-navbar-transparent" uk-navbar>
      <div class="uk-navbar-center">
        <ul class="uk-navbar-nav">
          <li><router-link to="/" v-lang.navigation.home></router-link></li>
          <li><router-link to="/about" v-lang.navigation.about></router-link></li>
          <li><router-link to="/games" v-lang.navigation.games></router-link></li>
          <li v-if="this.$store.state.jwt && authUserHasRole('player')"><router-link to="/accounts" v-lang.navigation.accounts></router-link></li>
          <!--<li v-if="this.$store.state.jwt && authUserHasRole('administrator')"><router-link to="/admin" v-lang.navigation.admin></router-link></li>-->
          <li v-if="this.$store.state.jwt"><router-link to="/profile" v-lang.navigation.profile></router-link></li>
          <li v-if="!this.$store.state.jwt"><router-link to="/login" v-lang.navigation.login></router-link></li>
          <li v-else><a v-on:click="logout" v-lang.navigation.logout></a></li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'Navigation',

  data() {
    return {
      payload: '',
      allowed_roles: [],
      token_checked: false,
    }
  },

  methods: {

    logout() {
      this.token_checked = false;
      this.allowed_roles = [],
      this.$store.state.jwt = "";
      this.payload = "";
      this.$cookie.delete("jwt");
      this.$router.push("/");
    },

    parseJwt (token) {
      try {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
        this.payload = JSON.parse(jsonPayload);
        return;
      } catch {
        this.logout();
      }
      
    },

    authUserHasRole(needed_role) {
      if (this.token_checked === false) {
        this.checkToken();
      }

      if (this.allowed_roles.includes(needed_role)) {
        return true;
      }
    },

    checkToken () {
      if(!this.$store.state.jwt || this.$store.state.jwt.length === 0)
      {
        return false;
      }
      
      this.checkTokenRequest()
      .then(res => {
        if(!res || res.response !== 'success') {
          this.logout();
          return false;
        }
      })

      this.parseJwt(this.$store.state.jwt);

      this.allowed_roles = this.payload.roles;
      this.token_checked = true;
    },

    checkTokenRequest() {
      
      let http = axios.create({
          timeout: 10000,
          headers: {'token': this.$store.state.jwt}
      });

      return http.get(this.reimkeimeApi + 'checkToken')
          .then(res => res.data)
          .catch (err => err)
    }

  },

}

</script>

<style scoped>
  #nav {
    color: #f1f1f1;
    font-weight: bold;
  }

  #nav a.router-link-exact-active {
    color: #dcdcdc;
    text-decoration: underline;

  }
</style>
