<template>

    <div>
        <button class="form-button-component">{{description}}</button>
    </div>
    
</template>

<script>
export default {
    name: "FormButton",
    props: ['description']
}
</script>

<style scoped>

    .form-button-component {
        font-family: arial;
        font-weight: bold;
        color: #FFFFFF !important;
        font-size: 14px;
        text-shadow: 0px 0px 3px #000000;
        box-shadow: 1px 1px 8px #A0BED1;
        padding: 15px 25px;
        border-radius: 2px;
        border: 1px solid #000000;
        background: #000000;
    }

    .form-button-component:hover {
        color: #FFFFFF !important;
        background: #212121;
        background: linear-gradient(to top, #181818, #222222);
        cursor: pointer;
    }

</style>