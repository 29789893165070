<template>
  <div id="app">
    <Navigation/>
    <router-view/>
  </div>
</template>
<script>

  import Navigation from "./components/Navigation";
  export default {
    name: "App",
    components: {Navigation},

    computed : {

      isJwtSet() {
        let jwt = this.$store.state.jwt;
        return !!(jwt && jwt.length > 0);
      }
    },

    created() {
      this.$store.state.jwt = this.$cookie.get("jwt");
    },
  }

</script>

<style lang="css">

  html {
    background: black !important;
  }

  #app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
</style>