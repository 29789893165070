import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Vuex from 'vuex'
import UIkit from 'uikit'
import Icons from 'uikit/dist/js/uikit-icons'
import 'uikit/dist/css/uikit.min.css'
import MultiLanguage from 'vue-multilanguage'
import VueCookie from 'vue-cookie'
import VueClipboard from 'vue-clipboard2'
import LoadScript from 'vue-plugin-load-script';

Vue.use(LoadScript);

UIkit.use(Icons);
Vue.use(Vuex);
Vue.use(VueCookie);
Vue.use(VueClipboard);

Vue.use(MultiLanguage, {
  default: 'de',
  de: {
    'navigation': {
      'home': 'Startseite',
      'about': 'Über uns',
      'games': 'Spiele',
      'login': 'Login',
      'logout': 'Logout',
      'accounts': 'Accounts',
      'profile': 'Profil',
    },
    'login': {
      'header': 'Login',
      'login': 'Anmelden',
      'email': 'E-Mail-Adresse',
      'password': "Passwort"
    },
    'account': {
      'nickname': 'Nickname',
      'actions': 'Aktionen',
      'action_copy_username': 'Username',
      'action_copy_password': 'Passwort',
      'action_change_password': 'Passwort ändern',
      'action_delete_account': 'Account löschen',
      'save': 'Passwort ändern',
      'cancel': 'Abbrechen',
      'delete_confirmation_text': 'Möchtest du diesen Account wirklich löschen?',
      'delete': 'Account löschen',
      'create_button': 'Account hinzufügen',
      'create_header': 'Account erstellen',
      'new_email': 'E-Mail-Adresse',
      'new_nickname': 'Profilname',
      'new_username': 'Username',
      'new_password': 'Passwort',
    },
    'error': {
      'credentialsIncorrect': "Falsche Zugangsdaten. Versuche es erneut!",
      'serverError': "Netzwerkfehler. Bitte versuche es später erneut!",
      'credentialsNotGiven': "Keine Zugangsdaten angegeben. Bitte alle Felder ausfüllen!"
    },
    
    
  },
  en: {
    'nav_home': 'Home',
    'nav_about': 'About us',
    'nav_games': 'Games',
    'nav_login': 'Login',
  },
});

Vue.config.productionTip = false;

Vue.prototype.reimkeimeApi = 'https://api.reimkeime.de/api/v1/';

const store = new Vuex.Store( {
  state: {
    jwt: "",
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
