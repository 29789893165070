import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import About from './views/About'
import Games from "./views/Games";
import Profile from "./views/Profile.vue"
import Login from "./views/Login";
import Accounts from "./views/Accounts";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/about',
      name: 'about',
      component: About
    },
    {
      path: '/games',
      name: 'games',
      component: Games
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/accounts',
      name: 'accounts',
      component: Accounts
    },
    {
      path: '/profile',
      name: 'profile',
      component: Profile
    },
  ]
})
