<template>
    <div class="login">

        <h2 class="uk-text-center uk-text-uppercase" v-lang.login.header></h2>

        <div class="login-struct uk-box-shadow-hover-large">

            <form action="javascript:;">

                <div v-lang.email></div>

                <input class="uk-input" type="text" autofocus v-model="email" v-bind:placeholder="translate('login.email')"/>

                <div v-lang.password></div>

                <input class="uk-input uk-margin-top" type="password" autofocus v-model="password" v-bind:placeholder="translate('login.password')"/>

                <div v-if="identityErrorMessage.length > 0">
                    {{translate('error.' + identityErrorMessage)}}
                </div>

                <div class="uk-text-center uk-margin-top">
                    <form-button v-bind:description="translate('login.login')" v-on:click.native="login"></form-button>
                </div>

            </form>

        </div>

    </div>

</template>

<script>

    import axios from 'axios';
    import FormButton from "../components/Basic/FormButton";

    export default {
        components: { FormButton },
        name: "Login",

        data() {
            return {
                email: "",
                password: "",
                identityErrorMessage: "",
            }
        },

        created() {

            if(this.$store.state.jwt) {

                this.$router.back();

            }

        },

        methods: {

            login() {

                if (this.email.length === 0 || this.password.length === 0 ) {
                    this.identityErrorMessage = 'credentialsNotGiven';
                    return;
                }

                let http = axios.create({timeout: 2000});

                http.post(this.reimkeimeApi+'users/login', {
                    "email": this.email,
                    "password": this.password,
                })

                    .then(response => {
                        this.identityErrorMessage = "";
                        let jwt = response.data.token;

                        this.$store.state.jwt = jwt;

                        this.$cookie.set("jwt", jwt, {expires: 1});

                        this.$router.back();

                    })

                    .catch(e => {
                        if(e.response && e.response.status === 422) {

                            this.identityErrorMessage = 'credentialsIncorrect';


                        }else {

                            this.identityErrorMessage = 'serverError';

                        }
                    })

            }

        }
    }
</script>

<style scoped>

    .login-struct {

        background: #fff;
        padding: 40px;
        margin: auto;
        max-width: 219px;
        color: #777;
        min-height: 232px;

    }

</style>