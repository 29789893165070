<template>

    <div class="accounts">
        <div class="controller">
            <button class="uk-button success pannel" uk-toggle="#create-account-modal" v-lang.account.create_button></button>
            <div id="create-account-modal" uk-modal>
                <div class="uk-modal-dialog uk-modal-body">
                    <h2 class="uk-modal-title" v-lang.account.create_header></h2>
                    <p>
                        <input class="uk-input" type="text" v-bind:placeholder="translate('account.new_email')" v-model="new_email">
                    </p>
                    <p>
                        <input class="uk-input" type="text" v-bind:placeholder="translate('account.new_nickname')" v-model="new_nickname">
                    </p>
                    <p>
                        <input class="uk-input" type="text" v-bind:placeholder="translate('account.new_username')" v-model="new_username">
                    </p>
                    <p>
                        <input class="uk-input" type="password" v-bind:placeholder="translate('account.new_password')" v-model="new_password">
                    </p>

                    <p class="uk-text-right">
                        <button class="uk-button uk-button-default uk-modal-close" type="button" v-lang.account.cancel></button>
                        <button class="uk-button success uk-modal-close" type="button" v-on:click="saveNewAccount()" v-lang.account.create_button></button>
                    </p>
                </div>
            </div>
        </div>
        <table class="table uk-margin-top">
            <tr v-for="(element, key) in computedAccounts" v-bind:key="key">
                <td class="table-row padding-right uk-text-bold">{{element.nickname}}</td>
                <td class="table-row">
                    <button class="uk-button-primary uk-button uk-margin-right" v-clipboard:copy="copyUsername(key)" v-lang.account.action_copy_username></button>
                    <button class="uk-button-primary uk-button uk-margin-right" v-clipboard:copy="copyPassword(key)" v-lang.account.action_copy_password></button>
                    <button class="uk-button-default uk-button uk-margin-right" v-bind:uk-toggle="'target: #change-password'+key" v-lang.account.action_change_password></button>
                    <button class="uk-button-danger uk-button" v-bind:uk-toggle="'target: #delete-account'+key" v-lang.account.action_delete_account></button>
                </td>
                <div v-bind:id="'change-password'+key" uk-modal>
                    <div class="uk-modal-dialog uk-modal-body">
                        <h2 class="uk-modal-title">{{element.nickname}}</h2>
                        <p>
                            <input type="password" class="uk-input" v-model="password">
                        </p>
                        <p class="uk-text-right">
                            <button class="uk-button uk-button-default uk-modal-close" type="button" v-lang.account.cancel></button>
                            <button class="uk-button success uk-modal-close" type="button" v-on:click="saveNewPassword(key)" v-lang.account.save></button>
                        </p>
                    </div>
                </div>
                <div v-bind:id="'delete-account'+key" uk-modal>
                    <div class="uk-modal-dialog uk-modal-body">
                        <h2 class="uk-modal-title">{{element.nickname}}</h2>
                        <p v-lang.account.delete_confirmation_text></p>
                        <input type="checkbox" v-model="delete_confirmation">
                        <p class="uk-text-right">
                            <button class="uk-button uk-button-default uk-modal-close" type="button" v-lang.account.cancel></button>
                            <button v-if="delete_confirmation === true" class="uk-button uk-button-danger uk-modal-close" type="button" v-on:click="deleteAccount(key)" v-lang.account.delete></button>
                            <button v-else class="uk-button uk-button-danger uk-disabled" v-lang.account.delete></button>
                        </p>
                    </div>
                </div>
            </tr>
        </table>
    </div>
    
</template>

<script>
    import axios from 'axios';

    export default {
        name: "Accounts",
        data() {
            return {
                accounts: [],
                delete_confirmation: false,
                password: '',
                new_nickname: '',
                new_username: '',
                new_password: '',
                new_email: '',
            }
        },

        created() {
            this.getAccountsOfUser();
        },

        computed: {
            computedAccounts() {
                let computedAccounts = this.accounts;
                return computedAccounts;
            }
        },

        methods: {

            saveNewAccount() {
                let http = axios.create({
                    timeout: 5000,
                    headers: {'token': this.$store.state.jwt}
                });

                http.post(this.reimkeimeApi + 'accounts', {
                    'email': this.new_email,
                    'username': this.new_username,
                    'nickname': this.new_nickname,
                    'password': this.new_password,
                })
                    .then(response => {
                        this.computedAccounts.push(response.data),
                        this.new_email = '',
                        this.new_username = '',
                        this.new_nickname = '',
                        this.new_password = ''

                        }
                    )
            },

            getAccountsOfUser() {
                let http = axios.create({
                    timeout: 10000,
                    headers: {'token': this.$store.state.jwt}
                });

                http.get(this.reimkeimeApi + 'accounts/user')
                    .then(response => {
                        this.accounts = response.data;
                    })
            },

            copyUsername(key) {
                return this.computedAccounts[key].username;
            },

            copyPassword(key) {
                return this.computedAccounts[key].password;
            },

            deleteAccount(key) {
                let http = axios.create({
                    timeout: 5000,
                    headers: {'token': this.$store.state.jwt}
                })

                http.delete(this.reimkeimeApi + 'accounts/'+ this.computedAccounts[key].id)
                    .then(
                        this.computedAccounts = this.computedAccounts.splice(key, 1),
                        this.delete_confirmation = false
                    )
            },

            saveNewPassword(key) {
                let http = axios.create({
                    timeout: 5000,
                    headers: {'token': this.$store.state.jwt}
                });

                http.put(this.reimkeimeApi + 'accounts/'+this.computedAccounts[key].id, {
                    "password": this.password
                })
                    .then(
                        this.computedAccounts[key].password = this.password,
                        this.password = ''
                    )
            },
        }
    }
</script>

<style scoped>

    .table {
        background: #fff;
        padding: 40px;
        margin: auto;
        width: 1100px;
        color: #777;
        min-height: 232px;
    }

    .padding-right {
        padding-right: 50px;
    }

    .table-row {
        height: 45px;
        text-align: center !important;
    }

    .success {
        background: #008209;
        color: white;
    }

    .controller {
        max-width: 1000px;
        margin: auto;
        background: white;
        padding: 40px;
    }

</style>