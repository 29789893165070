<template>
    <div class="home">
        <img alt="Reimkeime-Logo" src="../assets/ReimKeimeLOGO.png" width="650">
    </div>
</template>

<script>

    export default {
        name: 'home',
    }
</script>

