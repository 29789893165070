<template>
    <div class="games">

        <div v-for="(element, key) in games" v-bind:key="key">
            {{element.title}}
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Game",
        data() {
            return {
                games: [],

            }
        },

        created() {
            this.loadGames()
        },

        methods: {
            loadGames() {
                let http = axios.create({
                    timeout: 10000,
                    headers: {'token': this.$store.state.jwt}
                });

                http.get(this.reimkeimeApi + 'games')
                    .then(response => {
                        this.games = response.data;
                    })
            }
        }
    }
</script>

<style scoped>

</style>