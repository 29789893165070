<template>
    <div>
        <Game></Game>
    </div>
</template>

<script>
    import Game from "../components/Game";
    export default {
        name: 'games',
        components: {Game},
    }
</script>


