<template>

  <div class="profile">

    <div class="background">

      <h1>Dein Profil</h1>

      <div>



      </div>

    </div>

  </div>

</template>

<script>
  import axios from 'axios';

  export default {
    name: 'profile',
    data() {
            return {
                auth_user: {},
            }
        },

        created() {
            this.getAuthUserData();
        },

        computed: {
            
        },

        methods: {

          getAuthUserData() {
            let http = axios.create({
                    timeout: 10000,
                    headers: {'token': this.$store.state.jwt}
                });

                http.get(this.reimkeimeApi + 'users/token')
                    .then(response => {
                        this.auth_user = response.data;
                    })
          }

        }
  }
</script>

<style lang="css">

  .background {
        background: #fff;
        padding: 40px;
        margin: auto;
        width: 1100px;
        color: #777;
        min-height: 232px;
    }

</style>